import React from "react";
import { graphql } from "gatsby";
import ProfileInformation from "../components/profile-information";
import PrivateRoute from "../components/private-route";

export const query = graphql`
  query Profile($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Profile = (props) => {
  return <PrivateRoute component={ProfileInformation} props={props} />;
};
export default Profile;
