import {
  Alert,
  Button,
  Collapse,
  Container,
  IconButton,
  Typography,
  FormControl,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useState, useEffect } from "react";
import { getUser, logout, gtmPushUserInfo } from "../services/auth";
import { countriesEn } from "./lists/select-country-en";
import { countriesEs } from "./lists/select-country-es";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  getAlternateLang,
  professionOptionsEn,
  professionOptionsEs,
} from "../utils/alternate-lang";
import CloseIcon from "@mui/icons-material/Close";
import { buildTherapeuticAreasOptions } from "../utils/therapeutic-areas";

const ProfileInformation = (props) => {
  const data = useStaticQuery(graphql`
    query {
      therapeuticAreaEn: allStrapiTherapeuticalArea(
        filter: { locale: { eq: "en" } }
      ) {
        edges {
          node {
            Name
            Slug
            AlternateSlug
          }
        }
      }
      therapeuticAreaEs: allStrapiTherapeuticalArea(
        filter: { locale: { eq: "es-PA" } }
      ) {
        edges {
          node {
            Name
            Slug
            AlternateSlug
          }
        }
      }
    }
  `);

  const { t, language } = useI18next();
  const [therapeuticAreaOptions, setTherapeuticAreaOptions] = useState(
    buildTherapeuticAreasOptions(
      data.therapeuticAreaEn.edges,
      data.therapeuticAreaEs.edges
    )
  );

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "profile",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  const getAreasOfExpertise = () => {
    if (!getUser()?.areasOfExpertise) {
      return t("other");
    }
    const areasOfExpertise = JSON.parse(getUser().areasOfExpertise);
    if (!areasOfExpertise || areasOfExpertise.length === 0) {
      return t("other");
    }
    return therapeuticAreaOptions
      .filter((item) => areasOfExpertise.includes(item.id))
      .map((item) => (language === "en" ? item.nameEn : item.nameEs))
      .join(", ");
  };

  const getProfession = () =>
    language === "en"
      ? professionOptionsEn.find((p) => p.id === getUser().profession)?.content
      : professionOptionsEs.find((p) => p.id === getUser().profession)?.content;

  const theme = useTheme();
  const [openAlert, setOpenAlert] = useState(true);
  const onClickProfileEdit = (event) => {
    event.preventDefault();
    navigate(`/${language === "en" ? "en" : "es"}/profile-edit/`);
  };
  return (
    <Layout>
      <SEO
        seo={{
          metaTitle: "Profile",
          metaDescription: "profile",
          linkAlternateHref: "/" + getAlternateLang(language) + "/profile",
          linkHrefLang: getAlternateLang(language),
          noindex: true,
        }}
      />
      {getUser() && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 4,
            gap: 8,
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Typography variant="h1">{t("profile.yourProfile")}</Typography>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              p: 2,
              gap: 8,
            }}
          >
            {props.props.location?.state?.showAlert && (
              <Collapse in={openAlert}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {t("profile.updateSuccess")}
                </Alert>
              </Collapse>
            )}
            <Box>
              <Typography variant="h6">{t("register.firstName")}</Typography>
              <Typography>{getUser().userFirstName}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">{t("register.lastName")}</Typography>
              <Typography>{getUser().userLastName}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">{t("register.email")}</Typography>
              <Typography>{getUser().email}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">{t("register.country")}</Typography>
              <Typography>
                {language === "es"
                  ? countriesEs.find((c) => c.alpha2 === getUser().country)
                      ?.name
                  : countriesEn.find((c) => c.alpha2 === getUser().country)
                      ?.name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                {t("register.areasOfExpertise")}
              </Typography>
              <Typography>{getAreasOfExpertise()}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">{t("register.profession")}</Typography>
              <Typography>{getProfession()}</Typography>
            </Box>
          </Container>
          <FormControl variant="standard" sx={{ width: "35ch" }}>
            <Button
              variant="contained"
              onClick={onClickProfileEdit}
              style={{
                letterSpacing: "0.1em",
                backgroundColor: theme.palette.primary.main,
                marginBottom: "1em",
              }}
            >
              {t("profile.edit")}
            </Button>
            <Button
              style={{
                letterSpacing: "0.1em",
                marginTop: "1em",
              }}
              onClick={logout}
            >
              {t("profile.logOut")}
            </Button>
          </FormControl>
        </Container>
      )}
    </Layout>
  );
};

export default ProfileInformation;
